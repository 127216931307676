.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -o-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 5;
  overflow-y: auto;
}
.container > i {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  color: #fff;
  font-size: 1.5rem !important;
}
.container > .body {
  margin-top: 4.5rem;
  padding: 0 2rem;
}
