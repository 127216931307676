#mobileShortcut {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 790px) {
  #mobileShortcut {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background: rgb(0, 169, 206);
    background: linear-gradient(0deg, #fff 0%, #f1f1f1 100%);
    padding: 2rem;
  }
  /* 
  #mobileShortcut::before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(0, 169, 206, 1);
    background: linear-gradient(
      -150deg,
      rgba(0, 169, 206, 1) 0%,
      rgba(255, 199, 44, 1) 100%
    );
    content: "";
    width: 10rem;
    height: 10rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -o-border-radius: 2rem;
    border-radius: 2rem;
  } */
  /* #mobileShortcut::after {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #f1f1f1;
    content: "";
    width: 8rem;
    height: 8rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 0;
  } */
  #mobileShortcut > span {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    background-color: rgba(0, 169, 206, 0.15);
    -webkit-backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    -o-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 46%;
    height: 110px;
    margin-bottom: 8%;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -o-border-radius: 2rem;
    border-radius: 2rem;
    overflow: hidden;
    z-index: 1;
  }
  #mobileShortcut > span:nth-child(2n + 1) {
    margin-right: 8%;
    margin-bottom: 0;
  }
  #mobileShortcut > span:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
  #mobileShortcut > span:nth-last-child(4) {
    margin-bottom: 0;
  }
  #mobileShortcut > span i {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: rgba(0, 169, 206, 1);
    width: 3.5rem;
    height: 3.5rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem !important;
    text-align: center;
    line-height: 3.5rem;
    -webkit-box-shadow: 7px 7px 10px -5px rgba(0, 169, 206, 0.5);
    -moz-box-shadow: 7px 7px 10px -5px rgba(0, 169, 206, 0.5);
    -o-box-shadow: 7px 7px 10px -5px rgba(0, 169, 206, 0.5);
    box-shadow: 7px 7px 10px -5px rgba(0, 169, 206, 0.5);
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  #mobileShortcut > span h1 {
    position: relative;
    width: 100%;
    padding: 1rem;
    color: var(--pttLinkColor);
    font-size: 1rem !important;
    font-weight: var(--fontWeight);
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
