.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -o-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 5;
  overflow-y: auto;
}
.container > .inputField {
  position: relative;
  margin: 4rem 1rem 1rem 1rem;
  padding: 1rem;
}
.container > .inputField > input[type="text"] {
  background-color: #eee;
  width: 100%;
  height: 3rem;
  padding: 0 9rem 0 1rem;
  border: none;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
}
.container > .inputField > button {
  position: absolute;
  right: 1rem;
  background-color: var(--pttYellowColor);
  height: 3rem;
  padding: 0 2rem;
  border: none;
  color: #fff;
  font-weight: var(--fontWeight);
  text-align: center;
  -webkit-border-radius: 0 2rem 2rem 0;
  -moz-border-radius: 0 2rem 2rem 0;
  -o-border-radius: 0 2rem 2rem 0;
  border-radius: 0 2rem 2rem 0;
}
.container > i {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  color: #fff;
  font-size: 1.5rem !important;
}

.errorText {
  position: relative;
  margin: 1rem 0;
  padding: 0.5rem 0;
  border: solid 1px var(--dangerBgColor);
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
  color: var(--dangerBgColor);
  text-align: center;
}
.errorText::before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  border: solid 8px transparent;
  border-bottom: solid 8px var(--dangerBgColor);
}
